/* React imports */
import * as React from 'react';

/* MUI imports */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

/* Amplify imports */
import Amplify, {API} from 'aws-amplify';
import awsconfig from './aws-exports';
import {listGames} from "./graphql/queries";
import {useParams} from "react-router-dom";

/* GraphQL API imports */

// Subscriptions: see Option 2 below
// import {onCreateGame, onUpdateGame} from './graphql/subscriptions';

Amplify.configure(awsconfig);

// Modal box style
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    overflowX: 'auto',
    overflowY: 'auto',
    backgroundColor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

let pauseGamePolling = false;
const setPauseGamePolling = (val) => {
    pauseGamePolling = val;
}


// The App
const App = () => {
    const [games, setGames] = React.useState([])
    const [red_wins, setRed_wins] = React.useState(-1)
    const [blue_wins, setBlue_wins] = React.useState(-1)
    let params = useParams()
    let tournament = params.tournament

    /* Option 1: Polling for new games */
    React.useEffect(() => {
        // get games
        getGames();

        // set a timer to get games frequently
        const interval = setInterval(() => {
            getGames();
        }, 5000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Option 2: Subscribing to game updates... There may be way too many
       of these and the browser starts misbehaving with memory errors */
    /*
     React.useEffect(() => {
        getGames()
    }, [])

    // Subscribe to creation of a Game
    // eslint-disable-next-line
    const subscribe_create = API.graphql(graphqlOperation(onCreateGame)).subscribe({
        next: (gameData) => {
            getGames();
        }
    });

    // Subscribe to updation of a Game
    // eslint-disable-next-line
    var subscribe_update = API.graphql(graphqlOperation(onUpdateGame)).subscribe({
        next: (gameData) => {
      getGames();
        }
    });
    */

    // retrieve games via the graphql
    async function getGames() {
        if (pauseGamePolling) {
            return;
        }
        try {
            // grab *all* the entries since we filter ourselves
            var queryFilter = {tournament: {eq: tournament}}
            var gameData = await API.graphql({
                query: listGames,
                variables: {limit: 10000, filter: queryFilter}
            });
            var games = [...gameData.data.listGames.items];

            while (gameData.data.listGames.nextToken !== null) {
                console.log("nextToken is not null, there is more data!");
                gameData = await API.graphql({
                    query: listGames,
                    variables: {limit: 10000, filter: queryFilter, nextToken: gameData.data.listGames.nextToken}
                });
                games.push(...gameData.data.listGames.items);
            }

            const prunedGames = [];
            var aiWins = 0;
            var humanWins = 0;

            games.forEach(function (item, index) {
                if (item._deleted !== true) {
                    prunedGames.push(item);

                    if (item.winning_spymaster === "red") {
                        aiWins++;
                    } else if (item.winning_spymaster === "blue") {
                        humanWins++;
                    }
                }
            });


            // quick object compare in-place
            function compare(a, b) {
                // sort by ascending table id
                if (a.table < b.table) {
                    return -1;
                }
                if (a.table > b.table) {
                    return 1;
                }

                // descending _timestamp
                if (a._lastChangedAt > b._lastChangedAt) {
                    return -1;
                }
                if (a._lastChangedAt < b._lastChangedAt) {
                    return 1;
                }

                // ascending id (since _timestamp only has 60-second resolution)
                if (a.id > b.id) {
                    return -1;
                }
                if (a.id < b.id) {
                    return 1;
                }

                return 0;
            }

            // WebKit is running min-sort, so the right thing will happen
            // with multi conditions.
            prunedGames.sort(compare);

            // ok, push all the non-active games into a history property
            // it's assumed this is sorted by table id, and the active game
            // is the first row with that table id
            var tables = [];
            prunedGames.forEach(function (item, index) {
                // first item is a table, by definition
                if (tables.length === 0) {
                    item.history = [item]; // history starts with the current item
                    tables.push(item);
                    return;
                }

                // if this is the current table id, push into history
                if (item.table === tables[tables.length - 1].table) {
                    tables[tables.length - 1].history.push(item);
                    return;
                }

                // else push a new item into the tables array
                item.history = [item]; // history starts with the current item
                tables.push(item);
            });

            setGames(tables);
            setRed_wins(aiWins);
            setBlue_wins(humanWins);

            console.log("Refreshed Games");

        } catch (err) {
            console.log('error fetching games');
        }
    }

    // Status card
    const BasicCard = (props) => {
        return (
            <Card sx={{minWidth: 325}}>
                <CardContent sx={{color: "white", fontWeight: "bold", bgcolor: props.color}}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="h3" component="div">
                                {props.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h3" component="div">
                                {props.score >= 0 ? props.score : ""}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    // Modal box (for logs)
    const BasicModal = (props) => {
        const [open, setOpen] = React.useState(false);
        const handleOpen = () => {
            setPauseGamePolling(true);
            setOpen(true);
        }
        const handleClose = () => {
            setPauseGamePolling(false)
            setOpen(false);
        }
        const injectedHtml = {__html: props.text}

        return (
            <div>
                <Button sx={{color: props.color, textTransform: 'none'}}
                        onClick={handleOpen}>{props.buttonText}</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Grid container justifyContent="flex-end">
                            <Button onClick={handleClose}>[X]</Button>
                        </Grid>
                        <Typography id="modal-modal-description" sx={{mt: 2, display: 'inline-block'}}>
                            <span dangerouslySetInnerHTML={injectedHtml}/>
                        </Typography>
                    </Box>
                </Modal>
            </div>
        );
    }

    const GameStatus = (props) => {
        const row = props.row;

        return (
            <Box sx={{fontSize: 'large'}}>
                {row.table}<br/>
                <Link sx={{m: 0}}
                      href={row.url}
                      target="_blank">
                    Join
                </Link>
            </Box>
        )
    }

    const StatusChip = (props) => {
        const spymaster = props.color === "red" ? props.row.red_spymaster.replace(/\(table \d+\)/, '') : props.row.blue_spymaster;
        const to_go = props.color === "red" ? props.row.red_to_go : props.row.blue_to_go;
        const last_clue = props.color === "red" ? props.row.last_red_clue : props.row.last_blue_clue;
        const last_count = props.color === "red" ? props.row.last_red_count : props.row.last_blue_count;
        const last_correct = props.color === "red" ? props.row.last_red_correct : props.row.last_blue_correct;
        // const status = last_clue + " " + last_count + " (got " + last_correct + ")";

        var box_color = props.color === "red" ? "Crimson" : "CornFlowerBlue";
        var is_my_turn = props.row.whose_turn_color === props.color;
        var i_won = props.color === props.row.winning_spymaster

        return (
            <Card raised={is_my_turn}
                  style={is_my_turn ? {borderStyle: "solid", borderWidth: "1px", borderColor: box_color} :
                      i_won ? {borderStyle: "dashed", borderWidth: "5px", borderColor: box_color} : {}}>
                <Grid container padding={1} spacing={1} sx={{fontWeight: "bold"}}>
                    <Grid item sx={{fontSize: "20pt", color: box_color}} xs={2}>
                        {to_go}
                        <Box sx={{padding: 0, fontSize: "6pt"}}>
                            cards left
                        </Box>
                    </Grid>
                    <Grid style={{fontWeight: "initial", fontSize: "14pt"}} item xs={8}>
                        <span>{spymaster} {i_won && "won!"}</span><br/>
                        <span>
                            {last_clue ?
                                (<span style={{fontWeight: "bold"}}>{last_clue}&nbsp;{last_count}
                                    {" "}{"✓".repeat(last_correct)}{is_my_turn ? "..." : ""}
                                </span>) :
                                is_my_turn ? (<>to clue...</>) : ""
                            }
                        </span>
                    </Grid>
                </Grid>
            </Card>
        )
    }


    const HistoryStatusChip = (props) => {
        const winning_color = props.row.winning_spymaster;

        var box_color = winning_color === "red" ? "Crimson" :
            winning_color === "blue" ? "CornFlowerBlue" : "white";
        var foreground = winning_color ? "white" : "black";
        var timestamp = new Date(parseInt(props.row._timestamp) * 1000).toLocaleTimeString([], {
            hour: 'numeric',
            minute: '2-digit'
        }).replace(" AM", "a").replace(" PM", "p")
        var background = box_color;
        if (winning_color === "red")
            if (props.row.red_to_go > 0)
                background = "linear-gradient(" + box_color + ",rgba(0,0,0,0.85))";

        if (winning_color === "blue")
            if (props.row.blue_to_go > 0)
                background = "linear-gradient(" + box_color + ",rgba(0,0,0,0.85))";

        return (
            <Card sx={{background: background}}>
                <BasicModal color={foreground} buttonText={timestamp} text={props.row.log_txt}/>
            </Card>
        )
    }

    const GamesTableRow = (props) => {
        const {row} = props;

        return (
            <React.Fragment>
                <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell sx={{fontWeight: "bold", textAlign: "center"}} component="th" scope="row">
                        <GameStatus row={row}/>
                    </TableCell>
                    <TableCell><StatusChip row={row} color="red"/></TableCell>
                    <TableCell><StatusChip row={row} color="blue"/></TableCell>
                    <TableCell>
                        {row.history.length > 0 &&
                            <Grid container>
                                {row.history.map((historyRow) => (
                                    <Grid key={historyRow.id} item p={1}>
                                        <HistoryStatusChip row={historyRow}/>
                                    </Grid>
                                ))}
                            </Grid>
                        }
                    </TableCell>
                </TableRow>
            </React.Fragment>
        )
    }

    // Games table
    function GamesTable(rows) {
        return (
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow sx={{bgcolor: "black"}}>
                            <TableCell sortDirection="asc"
                                       sx={{color: "white", fontWeight: "bold", textAlign: "center"}}>Table</TableCell>
                            <TableCell sx={{color: "white", fontWeight: "bold", minWidth: "200px"}}>AI
                                Spymaster</TableCell>
                            <TableCell sx={{color: "white", fontWeight: "bold", minWidth: "200px"}}>Human
                                Spymaster</TableCell>
                            <TableCell sx={{color: "white", fontWeight: "bold", minWidth: "400px"}}>Game
                                Logs</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <GamesTableRow key={row.id} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    // Render the app
    return (
        <Container maxWidth="lg">
            <Box>
                <Link href="https://punzinc.com">https://punzinc.com</Link>
            </Box>
            <Box sx={{my: 2}}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Codenames Tournament: {tournament}<br/>
                </Typography>
            </Box>
            <Box sx={{mt: 4}}>
                <Typography variant="h5" component="h4" gutterBottom>
                    Standings
                </Typography>
            </Box>

            <Grid container>
                <Grid item p={1}>
                    <BasicCard name="AI" score={red_wins} color="Crimson"/>
                </Grid>
                <Grid item p={1}>
                    <BasicCard name="Humans" score={blue_wins} color="CornflowerBlue"/>
                </Grid>
            </Grid>

            <Box sx={{mt: 4}}>
                <Typography variant="h5" component="h4" gutterBottom>
                    Games
                </Typography>
            </Box>
            <Box>
                {GamesTable(games)}
            </Box>
        </Container>
    );
}

export default App;
