/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGame = /* GraphQL */ `
  query GetGame($id: ID!) {
    getGame(id: $id) {
      id
      table
      winning_spymaster
      whose_turn_color
      url
      red_spymaster
      blue_spymaster
      red_to_go
      blue_to_go
      last_red_clue
      last_red_count
      last_red_correct
      last_blue_clue
      last_blue_count
      last_blue_correct
      log_txt
      _cookie
      _timestamp
      _agent_parameters
      _server
      tournament
      game_state
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGames = /* GraphQL */ `
  query ListGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        table
        winning_spymaster
        whose_turn_color
        url
        red_spymaster
        blue_spymaster
        red_to_go
        blue_to_go
        last_red_clue
        last_red_count
        last_red_correct
        last_blue_clue
        last_blue_count
        last_blue_correct
        log_txt
        _cookie
        _timestamp
        _agent_parameters
        _server
        tournament
        game_state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGames = /* GraphQL */ `
  query SyncGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGames(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        table
        winning_spymaster
        whose_turn_color
        url
        red_spymaster
        blue_spymaster
        red_to_go
        blue_to_go
        last_red_clue
        last_red_count
        last_red_correct
        last_blue_clue
        last_blue_count
        last_blue_correct
        log_txt
        _cookie
        _timestamp
        _agent_parameters
        _server
        tournament
        game_state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
